<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <!-- 대상자 (교육계획 수립시 교육대상자 자동추가) -->
      <c-table
        ref="userGrid"
        title="대상자 (교육계획 수립시 교육대상자 자동추가)"
        :columns="userGrid.columns"
        :gridHeight="gridHeight"
        :data="userGrid.data"
        :filtering="false"
        :isFullScreen="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable"
        rowKey="userId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addUser" v-if="editable" />
              <c-btn v-if="editable && userGrid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeUser" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <!-- 대상 부서 (부서원 전체) -->
      <c-table
        ref="deptGrid"
        title="대상 부서 (부서원 전체)"
        :columns="deptGrid.columns"
        :gridHeight="gridHeight"
        :data="deptGrid.data"
        :filtering="false"
        :isFullScreen="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable"
        rowKey="deptCd"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addDept" v-if="editable" />
              <c-btn v-if="editable && deptGrid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeDept" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
      <c-table
        ref="seniorGrid"
        title="대상 선임명 (선임자 전체)"
        :columns="seniorGrid.columns"
        :gridHeight="gridHeight"
        :data="seniorGrid.data"
        :filtering="false"
        :isFullScreen="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable"
        rowKey="seniorId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addSenior" v-if="editable" />
              <c-btn v-if="editable && seniorGrid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeSenior" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-course-user',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      userGrid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            // 사용자
            label: '사용자',
            style: 'width:25%',
            align: 'center',
            sortable: false
          },
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width:25%',
            // 사업장
            label: '사업장',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width:25%',
            // 부서
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            style: 'width:25%',
            // 직책
            label: '직책',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      deptGrid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width:50%',
            // 사업장
            label: '사업장',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '부서',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      seniorGrid: {
        columns: [
          {
            name: 'seniorName',
            field: 'seniorName',
            // 선임명
            label: '선임명',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      userListUrl: '',
      userSaveUrl: '',
      userDeleteUrl: '',
      deptListUrl: '',
      deptSaveUrl: '',
      deptDeleteUrl: '',
      seniorListUrl: '',
      seniorSaveUrl: '',
      seniorDeleteUrl: '',
    };
  },
  computed: {
    gridHeight() {
      return String(window.innerHeight - 170) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.userListUrl = selectConfig.sop.edu.course.userlist.url;
      this.userSaveUrl = transactionConfig.sop.edu.course.usersave.url;
      this.userDeleteUrl = transactionConfig.sop.edu.course.userdelete.url;
      this.deptListUrl = selectConfig.sop.edu.course.deptlist.url;
      this.deptSaveUrl = transactionConfig.sop.edu.course.deptsave.url;
      this.deptDeleteUrl = transactionConfig.sop.edu.course.deptdelete.url;
      this.seniorListUrl = selectConfig.sop.edu.course.seniorlist.url;
      this.seniorSaveUrl = transactionConfig.sop.edu.course.seniorsave.url;
      this.seniorDeleteUrl = transactionConfig.sop.edu.course.seniordelete.url;
      this.getUserList();
      this.getDeptList();
      this.getSeniorList();
    },
    getUserList() {
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = { eduCourseId: this.popupParam.eduCourseId};
      this.$http.request((_result) => {
        this.userGrid.data = _result.data;
        this.$refs['userGrid'].selected = [];
      },);
    },
    getDeptList() {
      this.$http.url = this.deptListUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = { eduCourseId: this.popupParam.eduCourseId};
      this.$http.request((_result) => {
        this.deptGrid.data = _result.data;
        this.$refs['deptGrid'].selected = [];
      },);
    },
    getSeniorList() {
      this.$http.url = this.seniorListUrl;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = { eduCourseId: this.popupParam.eduCourseId};
      this.$http.request((_result) => {
        this.seniorGrid.data = _result.data;
        this.$refs['seniorGrid'].selected = [];
      },);
    },
    addUser() {
      this.popupOptions.title = '대상자 검색'; // 대상자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.userGrid.data, { userId: item.userId }) === -1) {
            saveData.push({
              eduCourseId: this.popupParam.eduCourseId,
              userId: item.userId,
            });
          }
        });

        this.$http.url = this.userSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getUserList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeUser() {
      let selectData = this.$refs['userGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.userDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getUserList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addDept() {
      this.popupOptions.title = '부서 추가'; // 부서 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.deptGrid.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              eduCourseId: this.popupParam.eduCourseId,
              deptCd: item.deptCd,
            });
          }
        });

        this.$http.url = this.deptSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getDeptList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeDept() {
      let selectData = this.$refs['deptGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deptDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getDeptList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addSenior() {
      this.popupOptions.title = '선임명 추가'; // 선임명 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/com/seniorDismissalPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSenior;
    },
    closeSenior(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.seniorGrid.data, { seniorId: item.seniorId }) === -1) {
            saveData.push({
              eduCourseId: this.popupParam.eduCourseId,
              seniorId: item.seniorId,
            });
          }
        });

        this.$http.url = this.seniorSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getSeniorList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeSenior() {
      let selectData = this.$refs['seniorGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.seniorDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getSeniorList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
